import React, { useEffect } from 'react'
import { useAuth } from '@chordcommerce/react-autonomy'
import { navigate } from 'gatsby'
import { Container, useColorMode } from 'theme-ui'
import { LoadingSpinner } from '../LoadingSpinner'

const Authenticate = () => {
  const { handleCallback } = useAuth()
  const [, setColorMode] = useColorMode()
  setColorMode('cheddy-mac')

  useEffect(() => {
    handleCallback().then(() => navigate('/account'))
  }, [])

  return (
    <Container sx={{ width: '100vw', height: '75vh' }}>
      <LoadingSpinner />
    </Container>
  )
}

export default Authenticate
