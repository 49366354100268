/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import AuthenticatePage from '~/components/Account/Authenticate'

const Authenticate = () => (
  <Layout hideWidget>
    <Metadata title="Sign in" description="Signin to your Goodles account" />
    <AuthenticatePage />
  </Layout>
)

export default Authenticate
